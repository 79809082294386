<template>
  <div class="page page-company-form">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            <template v-if="resident">
              {{ resident.name }}
            </template>
            Hesap Bilgileri
          </h3>
        </div>

        <AddResidentsToBatchForm ref="batchForm" />

        <v-col class="text-right">
          <v-btn
            v-if="can('edit-house-income') && $refs.batchForm"
            class="ms-2 mb-2"
            color="pink"
            v-bind="attrs"
            v-on="on"
            outlined
            small
            icon
            @click="handleSendNotificationClick"
            title="Bildirim Gönder"
            :loading="isLoading"
          >
            <v-icon small>mdi-message</v-icon>
          </v-btn>

          <v-menu
            offset-y
            bottom
            left
            v-if="can('edit-house-income') && resident"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ms-2 mb-2"
                color="pink"
                v-bind="attrs"
                v-on="on"
                outlined
                small
                title="Bağımsız Bölüm Mali Durum"
              >
                BB Mali Durum
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in resident.house_users"
                :key="item.house_id"
                :to="{
                  name: 'incomes.houses.show',
                  params: { id: item.house_id },
                }"
                v-ripple
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.house.block.name }}-{{ item.house.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("house.user_type." + item.house_user_type_id) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu
            offset-y
            bottom
            left
            v-if="resident && (can('edit-house') || can('show-house'))"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ms-2 mb-2"
                color="pink"
                v-bind="attrs"
                v-on="on"
                outlined
                small
                title="Bağımsız Bölüm Tanımına Git"
              >
                Bağımsız Bölüm
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in resident.house_users"
                :key="item.house_id"
                :to="{
                  name: can('edit-house')
                    ? 'definitions.houses.edit'
                    : 'definitions.houses.show',
                  params: { id: item.house_id },
                }"
                v-ripple
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.house.block.name }}-{{ item.house.name }}
                  </v-list-item-title>
                  <br />
                  <v-list-item-subtitle>
                    {{ $t("house.user_type." + item.house_user_type_id) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            class="ms-2 mb-2"
            color="pink"
            outlined
            small
            v-if="resident && (can('edit-resident') || can('show-resident'))"
            :to="{
              name: can('edit-resident')
                ? 'definitions.residents.edit'
                : 'definitions.residents.show',
              params: { id: resident.id },
            }"
            title="Sakin Tanımına Git"
          >
            <v-icon small dense>mdi-account-edit</v-icon>
          </v-btn>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </div>

      <div class="card-body">
        <rs-table-title-bar
          title=""
          icon=""
          @clear-filters="handleRemoveFiltersClick"
          @reload="load()"
          hide-edit
          hide-search
          hide-filter-clear
        >
          <template slot="buttons:prepend">
            <v-btn
              class="me-1 mb-1 float-end"
              color="pink"
              outlined
              small
              @click="$refs.debtCollectingForm.show()"
              v-if="can('edit-house-income') && debtCollectingEnabled"
            >
              Borç Tahsilat
            </v-btn>

            <v-btn
              class="me-1 mb-1 float-end"
              color="pink"
              outlined
              small
              @click="$refs.applyDeferForm.show()"
              v-if="
                can('edit-house-income') &&
                applyDeferEnabled &&
                selectedItems.length === 1
              "
            >
              Gecikme Uygula
            </v-btn>

            <v-btn
              class="me-1 mb-1 float-end"
              color="pink"
              outlined
              small
              @click="handleCancelDeferClick"
              v-if="can('edit-house-income') && cancelDeferEnabled"
            >
              Gecikme İptal
            </v-btn>

            <v-btn
              class="me-1 mb-1 float-end"
              color="pink"
              outlined
              small
              @click="$refs.collectingDebtMatchForm.show()"
              v-if="can('edit-house-income') && collectingDebtMatchEnabled"
            >
              Avans Borç Eşleştir
            </v-btn>

            <v-btn
              class="me-1 mb-1 float-end"
              color="pink"
              outlined
              small
              @click="$refs.refundCollectingForm.show"
              v-if="can('edit-house-income') && refundCollectingEnabled"
            >
              Avans İade
            </v-btn>

            <v-btn
              class="me-1 mb-1 float-end"
              color="pink"
              outlined
              small
              @click="$refs.enforcementForm.show()"
              v-if="can('edit-house-income') && enforcementEnabled"
            >
              İcra Başlat
            </v-btn>

            <v-btn
              class="me-1 mb-1 float-end"
              color="pink"
              outlined
              small
              @click="handleCloseDebtClick"
              v-if="can('edit-house-income') && applyDeferEnabled"
            >
              Borç Kapat / Gider
            </v-btn>

            <v-btn
              class="me-1 mb-1 float-end"
              color="pink"
              outlined
              small
              @click.prevent="handleEnforcementClick"
              v-if="
                can('see-enforcement') &&
                selectedItems.length === 1 &&
                selectedItems[0].has_enforcement
              "
            >
              İcra Dosyası
            </v-btn>
          </template>
        </rs-table-title-bar>

        <v-data-table
          class="no-wrap"
          v-bind="dataTableAttrs"
          v-if="cluster"
          :headers="headers"
          :items="listShown"
          :loading="isLoading"
          id="printTable"
          :options.sync="options"
          :server-items-length="total"
          :items-per-page.sync="itemsPerPage"
          item-key="uniqueKey"
          :item-class="getRowClass"
          v-model="selectedItems"
          disable-sort
        >
          <template v-slot:top>
            <v-btn
              color="pink"
              class="printbtn"
              outlined
              small
              @click="exportXLSX()"
            >
              <v-icon>mdi-file-table</v-icon>
              Dışa Aktar
            </v-btn>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:body.append="{ headers }">
            <rs-table-foot-totals
              :headers="headers"
              :totals="footTotals"
              v-if="resident"
              totals-text="Toplam"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.balance="{ value }">
            <rs-table-cell-balance colored normalized :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.description="{ item, value }">
            <template v-if="item.debt_id || item.collecting_id">
              <rs-table-cell-string limit="20" :value="value" />
            </template>
            <template v-else>
              <strong>
                {{ item.block }}-{{ item.door_number }}<br />
                {{ item.house_user_type }}
              </strong>
            </template>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.assessment="{ value }">
            <rs-table-cell-number muted-zeros price :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.payed_capital="{ value }">
            <rs-table-cell-number muted-zeros price :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.defer_assessment="{ value }">
            <rs-table-cell-number muted-zeros price :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.defer_payed="{ value }">
            <rs-table-cell-number muted-zeros price :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.total_balance="{ value, item }">
            <rs-table-cell-balance
              colored
              :normalized="item.debt_id || item.collecting_id"
              :value="value"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.balance_wo_enforcement="{ value }">
            <rs-table-cell-balance
              colored
              normalized
              muted-zeros
              :value="value"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.enforcement_balance="{ value, item }">
            <router-link
              :to="{
                name: 'enforcements.show',
                params: { id: item.enforcement_id },
              }"
              v-if="can('see-enforcement') && value > 0 && item.enforcement_id"
            >
              <rs-table-cell-balance :value="value" muted-zeros />
            </router-link>
            <router-link
              :to="{
                name: 'enforcements.list',
                query: { resident: item.resident_name },
              }"
              v-else-if="
                can('see-enforcement') && value > 0 && item.resident_name
              "
            >
              <rs-table-cell-balance :value="value" muted-zeros />
            </router-link>
            <rs-table-cell-balance v-else :value="value" muted-zeros />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.defer_balance="{ item, value }">
            <v-tooltip bottom v-if="item.stop_deferment_on">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="red" dark v-bind="attrs" v-on="on">
                  mdi-exclamation
                </v-icon>
              </template>
              {{ new Date(item.stop_deferment_on).toLocaleDateString() }}
              tarihinden itibaren gecikme işletilmeyecek.
            </v-tooltip>

            <v-tooltip
              bottom
              v-if="
                !item.has_enforcement &&
                !item.stop_deferment_on &&
                item.deferment_date &&
                new Date(item.last_payment_on) < new Date() &&
                new Date(item.deferment_date).toLocaleDateString() !==
                  new Date().toLocaleDateString()
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="yellow darken-3" dark v-bind="attrs" v-on="on">
                  mdi-exclamation
                </v-icon>
              </template>
              Gecikme hesabı
              {{ new Date(item.deferment_date).toLocaleDateString() }}
              tarihine göre yapıldı.
            </v-tooltip>

            <span>
              <rs-table-cell-balance colored normalized :value="value" />
            </span>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.last_payment_on="{ value }">
            <rs-table-cell-date
              :value="value"
              :css-class="{
                'text-danger font-weight-bold': value < new Date(),
              }"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.type="{ item }">
            {{ item.income_type || item.collecting_type || null }}
          </template>
        </v-data-table>

        <v-snackbar :value="selectedDebtAmount" timeout="-1" class="text-end">
          Seçilen Borçların Toplamı:
          <strong>
            <rs-table-cell-number price :value="selectedDebtAmount" />
          </strong>
        </v-snackbar>

        <v-divider class="mx-10" />

        <DebtTypeSummary :list="listShown" />

        <v-divider class="mx-10" />

        <v-tabs v-model="selectedTabSecondary">
          <v-tab href="#account-activity">
            {{ $t("headers.account_activity") }}
          </v-tab>
          <v-tab href="#resident-notes" v-if="can('edit-house-income')">
            Notlar
          </v-tab>
          <v-tab href="#defer-info">{{ $t("headers.defer_info") }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTabSecondary">
          <v-tab-item value="account-activity">
            <div class="py-5">
              <BalanceActivityList
                :resident-id="id"
                :resident="resident"
                v-if="id && resident"
                hide-debt-resident-form
                ref="balanceActivityList"
                @update:balance="load"
              />
            </div>
          </v-tab-item>
          <v-tab-item value="resident-notes">
            <div class="py-5">
              <NoteList
                :allow-add="true"
                :extra-params="{ resident_id: id }"
                :form-subtitle="resident ? resident.name : null"
              />
            </div>
          </v-tab-item>
          <v-tab-item value="defer-info">
            <div class="py-5">
              <DeferInfo :resident-id="id" ref="deferInfo" />
            </div>
          </v-tab-item>
        </v-tabs-items>

        <EnforcementForm
          :resident="resident"
          ref="enforcementForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <DebtCollectingForm
          :resident="resident"
          ref="debtCollectingForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <ApplyDeferForm
          ref="applyDeferForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <RefundCollectingForm
          ref="refundCollectingForm"
          :selected="selectedItems"
          @saved="loadBothLists()"
        />

        <CollectingDebtMatchForm
          ref="collectingDebtMatchForm"
          :selected="selectedItems"
          :list="listShown"
          @saved="loadBothLists()"
        />
      </div>
    </div>

    <rs-confirm
      ref="confirmCancelDefer"
      :loading="isLoading"
      @confirmed="handleCancelDeferSubmitClick"
      @cancelled="$refs.confirmCancelDefer.hide()"
    />

    <rs-confirm
      ref="confirmCloseDebt"
      :loading="isLoading"
      @confirmed="handleCloseDebtSubmitClick"
      @cancelled="$refs.confirmCloseDebt.hide()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { hasDataTable, hasPermissions, isPage } from "@/view/mixins";
import NoteList from "@/view/content/Notes/NoteList";
import {
  ApplyDeferForm,
  CollectingDebtMatchForm,
  DebtCollectingForm,
  EnforcementForm,
  RefundCollectingForm,
} from "@/view/pages/income/forms";
import BalanceActivityList from "../houses/BalanceActivityList";
import DebtTypeSummary from "../houses/DebtTypeSummary";
import DeferInfo from "../houses/DeferInfo";
import AddResidentsToBatchForm from "@/view/pages/communication/batch-notifications/AddResidentsToBatchForm";
import { utils, writeFile } from "xlsx";

export default {
  name: "ResidentDetail",
  components: {
    AddResidentsToBatchForm,
    ApplyDeferForm,
    BalanceActivityList,
    CollectingDebtMatchForm,
    DebtCollectingForm,
    DebtTypeSummary,
    DeferInfo,
    EnforcementForm,
    NoteList,
    RefundCollectingForm,
  },
  mixins: [hasDataTable, hasPermissions, isPage],
  props: {
    /**
     * Resident ID
     */
    id: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["cluster"]),
    listShown() {
      if (!this.list.length && !this.totals.length) {
        return [];
      }

      let listShown = [].concat(this.list);

      const totals = this.totals.map((item) => {
        item.isSelectable = false;
        item.itemClass = "bg-gray";
        return item;
      });

      listShown = listShown.concat(totals);

      listShown.sort((a, b) => {
        if (a.house_id > b.house_id) {
          return -1;
        } else if (a.house_id < b.house_id) {
          return 1;
        } else {
          if (a.itemClass === "bg-gray") {
            return -1;
          } else if (b.itemClass === "bg-gray") {
            return 1;
          }

          if (a.collecting_id && !b.collecting_id) {
            return -1;
          } else if (!a.collecting_id && b.collecting_id) {
            return 1;
          }

          if (a.debt_id === null) {
            return -1;
          } else if (b.debt_id === null) {
            return 1;
          } else {
            return a.last_payment_on > b.last_payment_on ? 1 : -1;
          }
        }
      });

      listShown = listShown.map((item) => {
        item.uniqueKey = Math.random().toString().split(".")[1];
        return item;
      });

      return listShown;
    },
    debtCollectingEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      const houseId = this.selectedItems[0].house_id;

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }

        if (parseFloat(item.total_balance) <= 0) {
          return false;
        }

        if (item.house_id !== houseId) {
          return false;
        }
      }

      return true;
    },
    applyDeferEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }

        if (item.is_deferment) {
          return false;
        }

        if (item.income_type_id === 15) {
          return false;
        }

        if (parseFloat(item.total_balance) > 0) {
          continue;
        }

        return false;
      }

      return true;
    },
    cancelDeferEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }

        if (parseFloat(item.defer_balance) > 0) {
          continue;
        }

        return false;
      }

      return true;
    },
    enforcementEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];
        if (item.has_enforcement) {
          return false;
        }

        if (item.income_type_id === 15) {
          // Already an enforcement debt from previous management company
          return false;
        }

        if (parseFloat(item.total_balance) > 0 && !item.has_enforcement) {
          continue;
        }

        return false;
      }

      return true;
    },
    refundCollectingEnabled() {
      if (
        this.selectedItems.length !== 1 ||
        this.selectedItems[0].total_balance > 0
      ) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }
      }

      return true;
    },
    collectingDebtMatchEnabled() {
      if (
        this.selectedItems.length !== 1 ||
        this.selectedItems[0].total_balance > 0
      ) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement) {
          return false;
        }
      }

      return true;
    },
    selectedDebtAmount() {
      let totalDebtAmount = 0;

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];
        if (item.debt_id) {
          totalDebtAmount += item.total_balance * 100;
        }
      }

      return totalDebtAmount / 100;
    },
  },
  watch: {
    id(newValue, oldValue) {
      if (parseInt(newValue) !== parseInt(oldValue)) {
        this.load();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load();
    });
  },
  data() {
    return {
      resident: null,
      selectedTabSecondary: null,
      footTotals: {},
      totals: [],
      headers: [
        {
          text: "Fiş Türü",
          value: "type",
        },
        {
          text: this.$t("labels.description"),
          value: "description",
        },
        {
          text: this.$t("labels.last_payment_date"),
          value: "last_payment_on",
        },
        {
          text: this.$t("labels.assessment"),
          value: "assessment",
          align: "end",
        },
        {
          text: this.$t("labels.payed"),
          value: "payed_capital",
          align: "end",
        },
        {
          text: this.$t("labels.balance"),
          value: "balance",
          align: "end",
        },
        {
          text: this.$t("labels.total_balance"),
          value: "total_balance",
          align: "end",
        },
        {
          text: this.$t("labels.defer_assessment"),
          value: "defer_assessment",
          align: "end",
        },
        {
          text: this.$t("labels.defer_payed"),
          value: "defer_payed",
          align: "end",
        },
        {
          text: this.$t("labels.defer_balance"),
          value: "defer_balance",
          align: "end",
          width: "120px",
        },
        {
          text: "İcra Bakiye",
          value: "enforcement_balance",
          align: "end",
        },
        {
          text: "İcra Hariç Bakiye",
          value: "balance_wo_enforcement",
          align: "end",
        },
      ],
      originalData: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      if (!this.id || this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.selectedItems = [];

      // Get house data
      this.$api
        .query(`residents/${this.id}`)
        .then((response) => {
          this.resident = Object.assign({}, response.data.data);
          this.pageMeta.title = this.resident.name + " Mali İşlemler";

          this.updateClusterId(this.resident.cluster_id);
        })
        .catch((error) => {
          this.handleError(error);
        });

      this.list = [];
      this.selectedItems = [];
      this.footTotals = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return this.$api
        .query(`income/residents/${this.id}`, { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals.pop();
            this.totals = response.data.meta.totals;
          }

          // this.setOwnersList();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          this.isLoading = false;
        });
    },
    exportXLSX() {
      try {
        const element = document.getElementById("printTable");
        const ws = utils.table_to_sheet(
          element.getElementsByTagName("TABLE")[0]
        );
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Export");
        return writeFile(wb, "Robosay - Sakin Hesap Bilgileri.xlsx");
      } catch (e) {
        this.handleError(e);
      }
    },
    handleDeferSaved() {
      this.loadBothLists();

      if (this.$refs.deferInfo) {
        this.$refs.deferInfo.loadList();
      }
    },
    handleDebtShareSaved() {
      this.loadBothLists();
    },
    handleCancelDeferClick() {
      this.$refs.confirmCancelDefer.show(
        "Seçili borçlarda gecikme borçlarını silmek istediğinizden emin misiniz?"
      );
    },
    handleCancelDeferSubmitClick() {
      this.$refs.confirmCancelDefer.hide();
      this.isLoading = true;

      const promises = [];

      for (const index in this.selectedItems) {
        promises.push(this.cancelDeferment(this.selectedItems[index]));
      }

      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.handleDeferSaved();
          }, 100);
        })
        .catch(() => {
          this.$toast.error(
            "Bir veya birden fazla borcun gecikmesi silinemedi"
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancelDeferment(item) {
      return this.$api
        .delete(`income/debts/${item.debt_id}/deferment`)
        .then(() => {
          this.$toast.success("Gecikme silindi");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleCloseDebtClick() {
      this.$refs.confirmCloseDebt.show(
        "Seçili borçları gider olarak kapatmak istediğinizden emin misiniz?"
      );
    },
    handleCloseDebtSubmitClick() {
      this.$refs.confirmCloseDebt.hide();

      if (this.isLoading || !this.applyDeferEnabled) {
        return false;
      }

      this.isLoading = true;
      const list = [];

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];
        list.push({
          debt_id: item.debt_id,
          house_id: item.house_id,
          resident_id: this.id,
          amount: item.total_balance,
          income_type_id: 1001, // LOSS
        });
      }

      return this.$api
        .post("expense/other-expenses/store-multiple", { data: list })
        .then(() => {
          this.$toast.success("Diğer giderler oluşturuldu");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadBothLists();
        });
    },
    getRowClass(item) {
      const lighten = "lighten-5";
      if (item.enforcement_id) {
        return "red lighten-4";
      } else if (item.collecting_id) {
        return "green " + lighten;
      } else if (item.debt_id === undefined) {
        return "font-weight-bold blue lead " + lighten;
      }
      return "";
    },
    loadBothLists() {
      if (this.$refs.balanceActivityList) {
        if (this.$refs.balanceActivityList.constructor.name === "Array") {
          this.$refs.balanceActivityList[0].loadList();
        } else {
          this.$refs.balanceActivityList.loadList();
        }
      }
    },
    handleEnforcementClick(item) {
      let id;

      if (item && item.enforcement_id) {
        id = item.enforcement_id;
      } else if (this.selectedItems.length === 1) {
        id = this.selectedItems[0].enforcement_id;
      }

      if (!id) {
        return false;
      }

      this.$router.push({
        name: "enforcements.edit",
        params: { id: id },
      });
    },
    handleSendNotificationClick() {
      this.$refs.batchForm.show([this.id]);
    },
  },
};
</script>

<style media="screen" lang="scss">
.printbtn {
  position: absolute;
  top: 11.5em;
}
.top-buttons {
  .v-btn:not(.v-btn--round).v-size--small {
    padding: 0 5px;
  }
}
</style>
